import React from 'react'
import HomeHeader from "../components/homepage-header"
import AniLink from "gatsby-plugin-transition-link/AniLink/index"
import Footer from "../components/footer"
import Header from "../components/header"
import { graphql } from 'gatsby'
import SanitizeHTML from "react-sanitized-html"

const AboutChild = (props) => {
  const page = props.pageContext.page;

  if(!page || typeof page === 'undefined')
    return null

  return (
    <main>
      <Header isAbout={true} {...props}/>

      <div className="container-fluid single-project">
        <article>
          <div className="row">
            <div className="col-md-8">
              <h1>{page.title}</h1>

              <SanitizeHTML  html={page.content}/>
            </div>
          </div>
        </article>
      </div>

      <Footer {...props} />
    </main>
  )
}

export const query = graphql`
  query {
    allPages {
      nodes {
          title
          content
          page_id,
          alternative_children {
            title
            slug
            content
          }
      }
    }
  }
`


export default AboutChild