import React from 'react'
import Headroom from 'react-headroom'

import 'bootstrap/dist/css/bootstrap.css'
import '../scss/main.scss'
import { graphql, Link, StaticQuery } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink";

class HomeHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSubMenu: false,
      showMagazineMenu: false,
      showProjectsMenu: false
    };
  }

  closeSubMenu(){
    this.setState({
      showSubMenu: false
    })
  }

  toggleSubMenu(){
    this.setState({
      showSubMenu: !this.state.showSubMenu
    })

    if(this.state.showSubMenu) {
      this.setState({
        showMagazineMenu: false,
        showProjectsMenu: false
      })
    }
  }

  render(){
    let sheyi = false

    if(typeof this.props.pageContext.navigation !== 'undefined') {
      sheyi = this.props.pageContext.navigation[0].options ? this.props.pageContext.navigation[0].options.showSheyiSection : this.props.pageContext.navigation[1].options.showSheyiSection;
    }

		return (
      <React.Fragment>
        <div id="top" />
        <Headroom style={{backgroundColor: this.props.background ? this.props.background : '#fff'}} className={"main homepage" + (this.props.background === 'Black' ? ' black-header' : '')}>
          <div className="container-fluid">
            <div className="row">
              <div className="col d-flex d-md-block justify-content-between">
                <Link to={'/'}>Next Level</Link>

                <div className="d-md-none">
                  <button onClick={() => this.toggleSubMenu()}>Menu</button>
                </div>
              </div>

              <div className="col d-none d-md-block">
                <AniLink fade to={'/magazines/'}>
                  Magazine
                </AniLink>
              </div>

              <div className="col d-none d-md-block">
                <AniLink fade to={'/projects/'}>
                  Projects
                </AniLink>
              </div>

              <div className="col d-none d-md-block">
                <AniLink fade to={'/about'}>
                  About
                </AniLink>
              </div>

              {sheyi &&
                <div className="col d-none d-md-block">
                  <AniLink fade to={'/sheyi-bankale'}>
                    Sheyi Bankale
                  </AniLink>
                </div>
              }
            </div>
          </div>

          <div className={"dropdown homepage-dropdown " + (this.state.showSubMenu ? 'active' : '')}>
            <div className="container-fluid">
              <div className="mobile-link">
                <AniLink fade to={'/magazines/'}>
                  Magazine
                </AniLink>
              </div>

              <div className="mobile-link">
                <AniLink fade to={'/projects/'}>
                  Projects
                </AniLink>
              </div>

              <div className="mobile-link">
                <AniLink fade to={'/about'}>
                  About
                </AniLink>
              </div>

              {sheyi &&
              <div className="mobile-link">
                <AniLink fade to={'/sheyi-bankale'}>
                  Sheyi Bankale
                </AniLink>
              </div>
              }
            </div>
            </div>
        </Headroom>
      </React.Fragment>
		)
	}
}

export default HomeHeader;
